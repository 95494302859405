/*
Template Name: Velzon - Admin & Dashboard Template
Author: Themesbrand
Website: https://themesbrand.com/
Contact: support@themesbrand.com
File: Custom Css File
*/

/* Custom dark scrollbar for webkit browsers (Chrome, Safari) */
.table-responsive::-webkit-scrollbar {
  width: 10px; /* Set the width of the vertical scrollbar */
  height: 10px; /* Set the height of the horizontal scrollbar */
}

.table-responsive::-webkit-scrollbar-track {
  background: #ffffff; /* Dark background for the scrollbar track */
  border-radius: 10px; /* Optional: round the corners of the track */
}

.table-responsive::-webkit-scrollbar-thumb {
  background: #ffffff; /* Dark color for the scrollbar thumb */
  border-radius: 10px; /* Round the corners of the thumb */
  border: 2px solid #2e2e2e; /* Optional: add some padding around the thumb */
}

.table-responsive::-webkit-scrollbar-thumb:hover {
  background: #777; /* Lighter color for the thumb on hover */
}

/* Custom dark scrollbar for Firefox */
.table-responsive {
  scrollbar-color: #000000 #cfcfcf; /* Thumb color, track color */
  scrollbar-width: none; /* Thin scrollbar width */
}

.css-b62m3t-container {
  .css-13cymwt-control {
    background-color: var(--#{$prefix}gray-200);
    border-color: var(--#{$prefix}border-color);
    &:hover {
      border-color: var(--#{$prefix}border-color);
    }
  }
  .css-t3ipsp-control {
    background-color: var(--#{$prefix}gray-200);
    border-color: var(--#{$prefix}border-color);
    box-shadow: none;
    &:hover {
      border-color: var(--#{$prefix}border-color) !important;
    }
  }
  .css-1nmdiq5-menu {
    background-color: var(--#{$prefix}gray-200);
    .css-10wo9uf-option {
      cursor: pointer;
      background-color: var(--#{$prefix}gray-200);
      &:active {
        background-color: var(--#{$prefix}gray-200);
      }
    }
    .css-d7l1ni-option {
      cursor: pointer;
      background-color: var(--#{$prefix}gray-100);
    }
  }
  .css-1p3m7a8-multiValue {
    background-color: var(--#{$variable-prefix}light);
  }
  .css-wsp0cs-MultiValueGeneric {
    color: $heading-color;
  }
  .css-12jo7m5 {
    color: $heading-color;
  }
  .css-6j8wv5-Input,
  .css-qc6sy-singleValue {
    color: $heading-color;
  }

  .css-1dimb5e-singleValue {
    color: var(--vz-body-color);
  }
}

.marketplace-swiper .swiper-button-next,
.marketplace-swiper .swiper-button-prev {
  top: 24px;
}

.marquee {
  width: 100%;
  overflow: hidden;
  position: relative;
  height: 22px;
  line-height: 2;
}

.marquee div {
  display: block;
  width: 200%;
  height: 30px;
  position: absolute;
  overflow: hidden;
  animation: marquee 35s linear infinite;
}

.marquee span {
  float: left;
  width: 50%;
}

@keyframes marquee {
  0% {
    left: 0;
  }

  100% {
    left: -100%;
  }
}

// Data Table

.kVrXuC,
.dwPlXY,
.gKbhqU,
.iSAVrt {
  background-color: $table-bg !important;
  color: $table-color !important;
}

.fyrdjl {
  color: rgba(var(--#{$prefix}body-color-rgb), 0.54) !important;
  fill: rgba(var(--#{$prefix}body-color-rgb), 0.54) !important;
}

.fyrdjl:disabled {
  color: rgba(var(--#{$prefix}body-color-rgb), 0.18) !important;
  fill: rgba(var(--#{$prefix}body-color-rgb), 0.18) !important;
}

.iSAVrt {
  background-color: var(--#{$prefix}card-bg-custom) !important;
  border-bottom-color: $table-border-color !important;
}

.dwPlXY:not(:last-of-type) {
  border-bottom-color: $table-border-color !important;
}

.blogtablemain {
  .table-responsive {
    // scrollbar-color: #000000 #cfcfcf !important;
    scrollbar-color: #c1c1c1 #f1f1f1 !important;
    scrollbar-width: thin !important;
  }
  .thead{
    width: 100%;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px 0 0 10px;
    cursor: pointer;
  }
  ::-webkit-scrollbar {
    height: 5px;
    width: 7px;
  }
}

.form-search-input-main {
  &::-webkit-search-cancel-button {
    cursor: pointer;
    font-size: large;
    filter: invert(100%) grayscale(100%) brightness(0) saturate(100%);
  }
}

.user-profile-modal-main {
  max-width: 1000px;
  padding: 20px;

  .modal-content {
    border-radius: 16px;
    min-height: 700px;

    .modal-header {
      padding: 20px;
      border-bottom: 1px solid #ced4da;

      h5 {
        font-size: 28px;
        line-height: 38px;
        font-family: Montserrat-Bold;
      }

      .btn-close {
        width: 40px;
        height: 40px;
        padding: 0;
        font-size: 16px;
        transition: 0.4s ease-in-out;

        &:hover {
          transform: rotate(180deg);
        }
      }
    }

    .modal-body {
      padding: 20px;
      border-bottom: 1px solid #ced4da;

      .profile-section {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 30px;
      }

      .profile-column {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        
        @media (max-width: 1024px) {
          grid-template-columns: repeat(2, 1fr);
        }
        
        @media (max-width: 768px) {
          grid-template-columns: repeat(1, 1fr);
        }

        .profile-row {
          display: flex;

          strong {
            min-width: max-content;
            margin: 0 10px 0 0;
            font-weight: 700 !important;
          }
        }

        strong {
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
          color: #495057;
          font-family: Montserrat-Medium;
        }
        span {
          font-size: 13px;
          line-height: 20px;
          font-weight: 400;
          color: #495057;
          font-family: Montserrat-Medium;
        }
      }

      .profile-modal-payment-history-table-main {
        overflow: auto;

        .profile-modal-payment-history-table-tite {
          font-weight: 700;
        }

        tr {
          border: none !important;

          td,
          th {
            border-width: 0 0 1px 0;
            border-color: #e9ebec;
            flex-wrap: wrap;
          }
        }
        table {
          margin: 30px 0;
          width: 100%;
          white-space: nowrap;

          tbody {
            tr {
              td {
                font-size: 12px;
                align-items: center;
                align-self: center;
                justify-content: center;
                flex-wrap: wrap;
              }

              .successed {
                color: green;
              }

              .failed {
                color: red;
              }
            }
          }
        }

        .pagination {
          justify-content: end;
        }
      }
      .noRecord{
        font-size: 15px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 370px;
      }
    }

    .modal-footer {
      padding: 10px 20px;
    }
  }
}

.contact-modal-main {
  width: 100%;
  max-width: 700px;

  .modal-content {
    border-radius: 16px;
    min-height: 300px;

    .modal-header {
      padding: 20px;
      border-bottom: 1px solid #ced4da;

      h5 {
        font-size: 18px;
        line-height: 38px;
        font-family: Montserrat-Bold;
      }

      .btn-close {
        width: 40px;
        height: 40px;
        padding: 0;
        font-size: 16px;
        transition: 0.4s ease-in-out;

        &:hover {
          transform: rotate(180deg);
        }
      }
    }
    .modal-body {
      padding: 20px;
      border-bottom: 1px solid #ced4da;
    }

    .modal-footer {
      padding: 10px 20px;
    }
  }
}
